<template>
  <div style="overflow: hidden !important;">
    <div id="liveGs" ref="liveGS">
      <NavBarFreeMain />
    </div>
    <div v-if="colorsConfig.show_logo && rightBetTournamentSelected.showLogos" class="bet-logo">
      <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
    </div>
    <div class="landing-tops is-centered">
      <div class="landing-tops__container">
        <div id="betTool" ref="apostadorGS">
          <div class="gol-tipster">
            <div class="gol-tipster__body">
              <GolTipsterV2
                :await-for-right-bet-data="true"
                :right-bet-tournaments="rightBetTournaments"
                :parlay-bets="parlayBets"
                :are-live-games="areLiveGames"
                @tournament-change="onTournamentChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="landing-tops is-centered" v-if="areComponentsVisible">
      <div class="landing-tops__container">
        <div v-if="colorsConfig.show_logo && rightBetTournamentSelected.showLogos" class="bet-logo">
          <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
        </div>
        <div ref="datosGS">
          <GolstatsData
            :custom="colorsConfig"
            :await-for-tournaments="true"
            :tournaments="tournaments"
            @season-selected="seasonSelected"
            @tournament-selected="tournamentSelected"
            @seasons-selected="onSeasonsSelected"
          />
        </div>
        <div ref="mejoresGS">
          <Tops :season="seasonUpdate" :custom="colorsConfig" />
        </div>
        <div v-if="colorsConfig.show_logo" class="bet-logo">
          <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
        </div>
        <div id="Calendario" class="landing-tops__section-1-desktop">
          <div ref="previasGS">
            <Calendar
              type="onlycomponent"
              :custom="colorsConfig"
              :seasons="seasonsSelectedForCalendar"
              :await-for-seasons="awaitForSeasonOrSeasons"
              is-full
              @tournament-selected="onTournamentSelected"
            />
          </div>
        </div>
        <div class="landing-tops__section-table-only-pc is-centered">
          <div id="Tabla-general" class="landing-tops__section-table-only-pc__container-table">
            <div ref="tablaGS">
              <div ref="tablaGS2">
                <Table
                  :season="seasonUpdate"
                  :custom="colorsConfig"
                  :await-for-season="awaitForSeasonOrSeasons"
                  @data-fetched="onTableDataFetched"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="colorsConfig.show_logo" class="bet-logo">
          <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
        </div>
      </div>
    </div>
    <FooterGolTipster />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import axios from 'axios';
import { freePïcksRightBet, freePïcksParlay, freePicksSimulator, freePicksPersonalized } from '@/utils/analytics';
import { fetchColorsConfigByTeam } from '@/utils/api/profile';
import { fetchTournaments, fetchRightBetTournaments } from '@/utils/api/tournaments';
import { fetchLiveGames } from '@/utils/api/games';
import { fetchParlays } from '@/utils/api/bets';

export default {
  name: 'GolTipster',
  components: {
    GolstatsData: () => import('@/views/GolstatsData'),
    Tops: () => import('@/views/Tops'),
    Calendar: () => import('@/components/Sections/Calendar/CalendarComponent'),
    Table: () => import('@/components/Sections/Table/TableComponent'),
    NavBarFreeMain: () => import('@/components/Sections/Main/NavBarFreeMain'),
    BetLogo: () => import('@/components/Elements/BetLogo'),
    GolTipsterV2: () => import('@/components/Sections/GolTipster-v2/Main'),
    FooterGolTipster: () => import('@/components/Sections/Main/FooterGolTipster'),
  },
  data() {
    return {
      season: null,
      tournament: null,
      seasonsSelectedForCalendar: [],
      seasonsSelectedForRightBet: [],
      sectionScroll: 'apostadorGS',
      colorsConfig: {
        subHeaderColor: '#353535',
        headerBetToolBackground: '#242424',
        tournaments: false,
        color: '#cbee6b',
        url_base:
          'http://online.caliente.mx/promoRedirect?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54623980&lp=0&var1=web',
        team_id: 1,
        type: 1,
        analytics: true,
        headerColor: '#CBEE6E',
        partner_id: 11,
        bet_house: 'caliente',
        header2Color: '#FFFFFF',
        logo: 'http://golstatsimages.blob.core.windows.net/content/escudo_golstats.png',
        categories: 'all',
        subheaderBetToolBackground: '#151515',
        subHeaderBackground: '#cbee6b',
        backgroundheader: '#CCE8B5',
        header2Background: '#132839',
        show_logo: true,
        headerBorderColor: '#CBEE6E',
        secondary_logo: 'https://az755631.vo.msecnd.net/golstats-bets/logo_caliente.svg',
        colorheader: '#132839',
        background: '#242424',
        main_logo: 'https://az755631.vo.msecnd.net/golstats-bets/logo_caliente_url2x.png',
        headerBackground: '#132839',
      },
      sections: [
        {
          id: 1,
          name: 'Apuesta\nderecha',
          componentName: 'RightBet',
          isBordered: true,
          hasHeader: true,
          eventAnalytics: () =>
            freePïcksRightBet({
              is_premium: this.getPremiumAccount,
              golstats: this.getIsGolstats,
            }),
        },
        {
          id: 2,
          name: 'Parlay',
          componentName: 'Parlay',
          isBordered: true,
          hasHeader: true,
          eventAnalytics: () =>
            freePïcksParlay({
              is_premium: this.getPremiumAccount,
              golstats: this.getIsGolstats,
            }),
        },
        {
          id: 3,
          name: 'Simulador',
          componentName: 'Simulador',
          isBordered: false,
          hasHeader: false,
          isFullWidth: true,
          eventAnalytics: () =>
            freePicksSimulator({
              is_premium: this.getPremiumAccount,
              golstats: this.getIsGolstats,
            }),
        },
        {
          id: 4,
          name: 'Personalizado',
          componentName: 'Personalizado',
          isBordered: false,
          hasHeader: false,
          isFullWidth: true,
          eventAnalytics: () =>
            freePicksPersonalized({
              is_premium: this.getPremiumAccount,
              golstats: this.getIsGolstats,
            }),
        },
      ],
      selectedSection: {},
      tournaments: [],
      rightBetTournaments: [],
      parlayBets: { tournaments: [] },
      areLiveGames: false,
      areComponentsVisible: false,
      rightBetTournamentSelected: {},
    };
  },
  computed: {
    ...mapState(['displayWidth']),
    ...mapState('loginGeneral', ['user']),
    ...mapState('general', ['sectionRef']),
    ...mapState('betsGeneral', ['refreshIntervalCounter']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats', 'isLoggedIn']),
    awaitForSeasonOrSeasons() {
      if (!this.colorsConfig.tournaments) {
        return true;
      }
      return this.colorsConfig.tournaments;
    },
    seasonUpdate() {
      return this.season;
    },
    isPremiumUser() {
      return this.isLoggedIn && this.getPremiumAccount;
    },
    tournamentUpdate() {
      return this.tournament;
    },
  },
  watch: {
    sectionRef(newValue) {
      if (!this.areComponentsVisible) {
        this.areComponentsVisible = true;
        setTimeout(() => {
          this.scrollToElement(newValue);
        }, 2000);
      } else {
        this.scrollToElement(newValue);
      }
    },
    refreshIntervalCounter() {
      this.fillRightBetData();
    },
  },
  methods: {
    ...mapMutations(['SET_PREMIUM_MESSAGE']),
    ...mapMutations('general', ['setTournamentSelected', 'setSeccionBetsGolTipster']),
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapMutations('profileGeneral', ['setColorsConfig']),
    async fetchMainData() {
      try {
        const [tournaments, rightBetTournaments, parlayBets, liveGames, colorsConfig] = await Promise.all([
          fetchTournaments(),
          fetchRightBetTournaments(),
          fetchParlays(),
          fetchLiveGames(),
          fetchColorsConfigByTeam(),
        ]);
        this.rightBetTournaments = rightBetTournaments;
        this.parlayBets = parlayBets;
        this.areLiveGames = !!liveGames;
        this.tournaments = tournaments;
        this.fillBetLogos(colorsConfig);
      } catch (e) {
        throw new Error(e);
      }
    },
    async fillRightBetData() {
      try {
        const [parlayBets] = await Promise.all([fetchParlays()]);
        this.parlayBets = parlayBets;
      } catch (e) {
        throw new Error(e);
      }
    },
    onTournamentSelected(tournament) {
      this.setTournamentSelected(tournament);
    },
    fillBetLogos(colorsConfig) {
      try {
        this.colorsConfig.bet_house = colorsConfig.bet_house;
        this.colorsConfig.main_logo = colorsConfig.main_logo;
        this.colorsConfig.secondary_logo = colorsConfig.secondary_logo;
        this.colorsConfig.show_logo = colorsConfig.show_logo;
        this.colorsConfig.url_base = colorsConfig.url_base;
        this.setColorsConfig(colorsConfig);
      } catch (e) {
        throw new Error(e);
      }
    },
    async sendLog(logType) {
      try {
        if (this.$route.name !== 'gana') {
          return;
        }
        const logsUrl = 'https://95ow8l4hkh.execute-api.us-east-2.amazonaws.com/prod/visitlogs';
        const body = {
          name: 'gana',
          ref: document.referrer || 'home',
          type: logType,
          user_type: this.user.premiumAccount ? 2 : 1,
        };
        await axios.post(logsUrl, body);
      } catch (e) {
        throw new Error(e);
      }
    },
    onTableDataFetched() {
      this.sendLog(2);
    },
    scrollToElement(val) {
      let el = this.$refs[val];
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      if (vw < 1080 && val == 'tablaGS') {
        el = this.$refs['tablaGS2'];
      }
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    handleScrolling(event) {
      if (this.$refs['datosGS']) {
        let sizeDatosGS = this.$refs['datosGS'].offsetTop + this.$refs['datosGS'].offsetHeight;
        let scrollCalc = event.target.scrollTop + 136;
        if (scrollCalc >= this.$refs['datosGS'].offsetTop && scrollCalc <= sizeDatosGS) {
          this.sectionScroll = 'datosGS';
        }
      }
      if (this.$refs['testimoniosGS']) {
        let sizeDatosGS = this.$refs['testimoniosGS'].offsetTop + this.$refs['testimoniosGS'].offsetHeight;
        let scrollCalc = event.target.scrollTop + 136;
        if (scrollCalc >= this.$refs['testimoniosGS'].offsetTop && scrollCalc <= sizeDatosGS) {
          this.sectionScroll = 'testimoniosGS';
        }
      }
      if (this.$refs['previasGS']) {
        let sizePreviasGS = this.$refs['previasGS'].offsetTop + this.$refs['previasGS'].offsetHeight;
        let scrollCalc = event.target.scrollTop + 136;
        if (scrollCalc >= this.$refs['previasGS'].offsetTop && scrollCalc <= sizePreviasGS) {
          this.sectionScroll = 'previasGS';
        }
      }
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      if (vw < 1080) {
        if (this.$refs['tablaGS2']) {
          let sizeTablaGS = this.$refs['tablaGS2'].offsetTop + this.$refs['tablaGS2'].offsetHeight;
          let scrollCalc = event.target.scrollTop + 136;
          if (scrollCalc >= this.$refs['tablaGS2'].offsetTop && scrollCalc <= sizeTablaGS) {
            this.sectionScroll = 'tablaGS';
          }
        }
      } else {
        if (this.$refs['tablaGS']) {
          let sizeTablaGS = this.$refs['tablaGS'].offsetTop + this.$refs['tablaGS'].offsetHeight;
          let scrollCalc = event.target.scrollTop + 136;
          if (scrollCalc >= this.$refs['tablaGS'].offsetTop && scrollCalc <= sizeTablaGS) {
            this.sectionScroll = 'tablaGS';
          }
        }
      }

      if (this.$refs['apostadorGS']) {
        let sizeApostadorGS = this.$refs['apostadorGS'].offsetTop + this.$refs['apostadorGS'].offsetHeight;
        let scrollCalc = event.target.scrollTop + 136;
        if (scrollCalc >= this.$refs['apostadorGS'].offsetTop && scrollCalc <= sizeApostadorGS) {
          this.sectionScroll = 'apostadorGS';
        }
      }
      if (this.$refs['mejoresGS']) {
        let sizeMejoresGS = this.$refs['mejoresGS'].offsetTop + this.$refs['mejoresGS'].offsetHeight;
        let scrollCalc = event.target.scrollTop + 136;
        if (scrollCalc >= this.$refs['mejoresGS'].offsetTop && scrollCalc <= sizeMejoresGS) {
          this.sectionScroll = 'mejoresGS';
        }
      }
    },
    seasonSelected($valor) {
      this.season = $valor;
    },
    tournamentSelected(tournament) {
      this.tournament = tournament.id;
      this.setTournamentSelected(tournament);
    },
    onSeasonsSelected(seasonsSelected) {
      this.seasonsSelectedForCalendar = seasonsSelected;
      this.seasonsSelectedForRightBet = seasonsSelected;
    },
    onSectionButtonClicked(sectionId) {
      if (sectionId !== 0) {
        this.selectedSection = this.sections.find(section => section.id === sectionId);
        this.setSeccionBetsGolTipster(this.selectedSection.id);
        this.selectedSection.eventAnalytics();
      }
    },
    onTournamentChange(tournament) {
      if (tournament) {
        this.rightBetTournamentSelected = tournament;
      }
    },
  },
  created() {
    setTimeout(() => {
      this.areComponentsVisible = true;
    }, 5000);
    this.fetchMainData();
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScrolling, true);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScrolling);
  },
};
</script>
<style>
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: calc(100% - 1px);
}
</style>

<style scoped lang="scss">
* {
  scroll-margin-top: 128px;
  @media screen and (max-width: 634px) {
    scroll-margin-top: 118px;
  }
}
.margin-none {
  margin-top: 25px !important;
}

.landing-tops {
  display: flex;
  justify-content: center;
  margin-bottom: 0px !important;
  &__section-only-bets {
    margin-top: 30px;
  }
  &__container {
    width: 100%;
    max-width: 1268px;
    height: auto;
  }
  &__section-table-only-pc {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 80px;
    &__container-table {
      width: 100%;
      max-width: 618px;
      height: auto;
    }
    @media screen and (max-width: 1080px) {
      display: flex;
    }
  }

  &__section-1 {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 40px;
    margin-top: 40px;
    &__calendar {
      margin-right: 5px;
      @media screen and (max-width: 1080px) {
        margin-right: 0px;
      }
    }
    &__table {
      margin-left: 5px;
      max-height: 700px;
      @media screen and (max-width: 1080px) {
        display: none;
      }
    }
    @media screen and (max-width: 1080px) {
      grid-template-columns: 100%;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
  &__section-1-type4 {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 40px;
    margin-top: 40px;
    &__calendar {
      margin-left: 5px;
      @media screen and (max-width: 1080px) {
        margin-right: 0px;
      }
    }
    &__table {
      margin-right: 5px;
      max-height: 700px;
      @media screen and (max-width: 1080px) {
        display: none;
      }
    }
    @media screen and (max-width: 1080px) {
      grid-template-columns: 100%;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
  &__section-1-desktop {
    display: grid;
    grid-template-columns: 100%;
    margin-bottom: 80px;
    margin-top: 40px;
  }
  &__section-1-desktop-2 {
    display: grid;
    grid-template-columns: 100%;
    margin-bottom: 80px;
    margin-top: 40px;
    @media screen and (max-width: 1080px) {
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
  &__section-calendar-only {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    display: none;
    &__container-calendar {
      width: 100%;
      max-width: 1268px;
      height: auto;
    }
    @media screen and (max-width: 1080px) {
      display: flex;
    }
  }
  &__section-table-only {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 80px;
    display: none;
    &__container-table {
      width: 100%;
      max-width: 618px;
      height: auto;
    }
    @media screen and (max-width: 1080px) {
      display: flex;
    }
  }
  &__section-table-only-desktop {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 40px;
    &__container-table {
      width: 100%;
      max-width: 618px;
      height: auto;
    }
  }
}
.gol-tipster {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 30px;
  &__body {
    width: 100%;
    max-width: 79.25rem;
    height: auto;
    background: white;

    &__bet-tool {
      width: 100%;

      &__body {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__title {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 2.25em 0;

          & > img {
            height: 2.75em;
            width: 2.75em;
            margin: 0.5em;
          }

          & > div {
            padding-top: 0.2em;
            font-size: 2.9em;
            font-family: Circular-Std-Bold, sans-serif;
          }

          @media screen and (max-width: 380px) {
            margin: 1.8em 0;
            font-size: 0.8rem !important;
          }

          @media screen and (max-width: 350px) {
            font-size: 0.7rem !important;
          }
        }

        &__buttons {
          display: flex;

          & > div {
            margin: 0 0.5rem;

            @media screen and (max-width: 744px) {
              flex-grow: 1;
            }

            @media screen and (max-width: 480px) {
              margin: 0 0.2rem;
            }
          }
        }

        &__section {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 1rem;
        }
      }
    }
  }
}

.bet-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1em 0 0.6em;
}
</style>

<style>
body {
  padding: 0 !important;
}
</style>
